<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Главная'" :breadCrumbFirstLink="'/'" :title="'Главная'">
      <div class="form-create__buttons"></div>
    </ToolsPanel>
    <ContentContainer>
      <div class="home">
        <div class="home__wrap">
          <div v-if="data" class="home__list">
            <div class="home__title">Developer Info</div>
            <div class="home__list-item"><b>Name:</b> {{ data.name }}</div>
            <div class="home__list-item"><b>Full Name:</b> {{ data.llcFullName }}</div>
            <div class="home__list-item"><b>Short Name:</b> {{ data.llcShortName }}</div>
            <div class="home__list-item"><b>Legal Address:</b> {{ data.legalAddress }}</div>
            <div class="home__list-item"><b>Post Address:</b> {{ data.postAddress }}</div>
            <div class="home__list-item"><b>inn:</b> {{ data.inn }}</div>
            <div class="home__list-item"><b>kpp:</b> {{ data.kpp }}</div>
            <div class="home__list-item"><b>ogrn:</b> {{ data.ogrn }}</div>
          </div>

          <div v-if="data" class="home__list">
            <div class="home__title">Credit</div>
            <div class="home__list-item"><b>Limit:</b> {{ data.credits.limit }}</div>
            <div class="home__list-item"><b>Remaining:</b> {{ data.credits.remaining }}</div>
            <div class="home__list-item"><b>Used:</b> {{ data.credits.used }}</div>
            <div class="home__list-item"><b>Start Date:</b> {{ data.credits.startDate }}</div>
            <div class="home__list-item"><b>End Date:</b> {{ data.credits.endDate }}</div>
          </div>
        </div>
      </div>
    </ContentContainer>
  </Main>
</template>

<script>
import Main from "~/templates/Main.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import { getStoredUserInfo } from "~/helpers/userAuth";
import usersApi from "~/api/user";

export default {
  name: "UserCreate",
  metaInfo: {
    title: "Главная Застройщик",
  },
  data() {
    return {
      data: null,
      user: {},
    };
  },
  components: {
    Main,
    ToolsPanel,
    ContentContainer,
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      if (getStoredUserInfo()) {
        this.user = getStoredUserInfo();

        const url = `/${this.user.developerId}?relations[0]=creditUsageLogs`;
        try {
          usersApi.getItemDeveloper(url).then((res) => {
            this.data = res.data;
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__title {
    font-size: 24px;
    padding-bottom: 15px;
    font-weight: bold;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
  }
  &__list-item {
    font-size: 18px;
    padding-bottom: 10px;
  }
}
</style>
